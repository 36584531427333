import React from 'react';
import { Tabs } from '@mantine/core';

const defaultTabStyles = (theme) => ({
  tab: {
    ...theme.fn.focusStyles(),
    // padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    padding: '8px 15px',
    background: 'transparent',
    borderRadius: 5,
    border: 0,
    color: '#000000',
    cursor: 'pointer',
    fontSize: theme.fontSizes.xs,
    display: 'flex',
    minWidth: 70,
    justifyContent: 'center',

    '&[data-active]': {
      background: theme.black,
      color: theme.white,
    },

    [theme.fn.smallerThan('xs')]: {
      flexShrink: 0,
    },
  },

  tabIcon: {
    marginRight: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
  },

  tabsList: {
    display: 'flex',
    border: '1px solid #dedede',
    borderRadius: 5,
    gap: 10,
    marginBottom: theme.spacing.lg,
    [theme.fn.smallerThan('xs')]: {
      flex: 'none',
      overflowY: 'auto',
      ...theme.other.hideScrollBar,
    },
  },
});

function TabPanel(props) {
  const panelProps = { ...props };
  if (panelProps.title) delete panelProps.title;
  if (panelProps.icon) delete panelProps.icon;
  return (
    <Tabs.Panel value={props.id} {...panelProps}>
      {props.children}
    </Tabs.Panel>
  );
}

export default function TabsComponent(props) {
  return (
    <Tabs {...props}>
      <Tabs.List>
        {React.Children.map(props.children, (child) => (
          <Tabs.Tab value={child.props.id} icon={child.props.icon || undefined}>
            {child.props.title}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {props.children}
    </Tabs>
  );
}

TabsComponent.Panel = TabPanel;

TabsComponent.style = defaultTabStyles;
